<template>
  <div class="d-flex flex-column overflow-y-auto flex-grow-1">
    <document-toolbar
      :tab="{typeName: ''}"
      no-history
      :special-actions="action"
    />
    <v-list>
      <v-list-item
        v-for="(tasks, uiGroup) in tasksByUiGroup"
        :key="uiGroup"
        class="d-flex flex-column"
      >
        <div style="width: 100%">{{uiGroup}} ></div>
        <grid
          class="ma-4 btns-grid"
          item-min-width="180px"
          :item-max-width="tasks.length >= 5 ? '1fr' : 'min(20vw, 250px)'"
          style="width: 100%"
        >
          <v-btn
            v-for="(task, taskIdx) in tasks"
            :key="taskIdx"
            color="primary"
            large
            height="100%"
            class="justify-start text-left font-weight-medium align-start py-4"
            @click="triggerTask(task)"
          >
            {{task.translate}}
          </v-btn>
        </grid>
      </v-list-item>
    </v-list>
    <confirmation ref="launchEngine">
      <confirmation-content
        :title="$t('t.TaskLaunchConfirm')"
        :message="consentMessage"
        :consent="true"
        :secured="secured"
      />
    </confirmation>
  </div>
</template>

<script>

export default {
  components: {
    Confirmation: () => import('@/components/confirmation'),
    ConfirmationContent: () => import('@/components/confirmation-content'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Grid: () => import('@/components/grid')
  },
  computed: {
    tasksByUiGroup: {
      get () {
        return this.lodash.groupBy(this.tasks, 'uiGroup')
      }
    }
  },
  props: {
    tasks: Array
  },
  data () {
    return {
      action: [{ text: this.$t('t.LogFile'), icon: this.$icon('i.Document'), callback: this.logFileOpen }],
      consentMessage: undefined,
      secured: false,
      showConfirmationDialog: false,
      title: undefined
    }
  },
  methods: {
    async logFileOpen () {
      this.$router.push('/logfile-v5')
    },
    async triggerTask (task) {
      this.consentMessage = this.$t('t.TaskLaunch', { task: task.translate.toLowerCase() })
      this.secured = task.secure
      if (await this.$refs.launchEngine.open()) {
        this.$http().post(`/core/orchestrator/${task.task}/manual`).then(() => {
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.TaskLaunched'))
        }).catch(e => {
          this.$store.dispatch('showErrorSnackbar', e.message)
        })
      }
      this.secured = false
    }
  }
}
</script>

<style lang="stylus" scoped>
>>>.v-btn__content
  white-space normal
  width 100%
  text-transform none

.btns-grid
  height fit-content
</style>
